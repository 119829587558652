import axios from 'axios'
import { useToast } from 'vue-toast-notification'

const $toast = useToast({position: 'top'});
const instance = axios.create({
    baseURL: import.meta.env.VITE_API_BASE
})

instance.interceptors.request.use(
    (config) => {
      config.withCredentials = true
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

instance.interceptors.response.use(
  (response) => {
    if(response.data.status === 'error') {
      $toast.error(response.data.msg)
    } 
    return response
  },
  (error) => {
    console.log(error)
    $toast.error(error.message)
    return Promise.reject(error);
  }
)

export default instance