<template>
    <div class="flex flex-col h-screen">
        <div class="flex-1 p-4 overflow-y-auto">
            <div class="text-gray text-sm mb-4">Создание плана {{ dt }}</div>
            <div class="flex mb-5">
                <Avatar :first-name="schedule.first_name" :last-name="schedule.last_name" size="md" />
                <div class="ml-4">
                    <div class="text-sm semibold text-darkgray">{{ schedule.last_name }} {{ schedule.first_name }} {{
                        schedule.middle_name }}
                    </div>
                    <div class="text-xs text-gray">{{ schedule.positions[0].position.name }}</div>
                    <div class="text-xs text-gray">{{ schedule.department.name }}</div>
                </div>
            </div>

            <EmployeeStats class="mb-3" :norm="schedule.shift_schedule?.norm_hours" :salary="schedule.salary_hour"
                :shifts-number="schedule.shifts.length" :plan="planHours" :extra="extraHours" :main="mainHours" />

            <Shifts :day-shifts="shifts" :schedule :date="dt" class="mb-4" />

            <ShiftsCopy />
        </div>

        <div class="text-right pb-4">
            <btn class="mr-5" @click="saveShift()">Сохранить</btn>
        </div>
    </div>
</template>

<script setup lang="ts">
interface Props {
    scheduleIndex: number,
    day: number
}
import { useStore } from '../../stores/store'
import { ref, onMounted, computed } from 'vue'
import Avatar from '../Avatar.vue'
import EmployeeStats from './EmployeeStats.vue'
import dayjs from "dayjs"
import Shifts from './Shifts.vue'
import ShiftsCopy from './ShiftsCopy.vue'

const store = useStore()

dayjs.locale('ru')

const { scheduleIndex, day } = defineProps<Props>()

const dt = computed(() => dayjs(store.currentScheduleDate).date(day).format('YYYY-MM-DD'))

const schedule = computed(() => store.schedules[scheduleIndex])

const shifts = computed(() => schedule.value.shifts.filter(shift => +dayjs(shift.date).format('D') === day))


const planHours = ref(0)
const extraHours = ref(0)
const mainHours = ref(0)

function getShiftHours(start?: string, end?: string) {
    return Math.round(dayjs(end).diff(dayjs(start), 'hour', true))
}

function calcHours() {
    schedule.value.shifts.forEach(shift => {
        planHours.value += +getShiftHours(shift.plan_work_start_at, shift.plan_work_end_at)
        if (shift.is_overtime) {
            extraHours.value += +getShiftHours(shift.plan_work_start_at, shift.plan_work_end_at)
        } else {
            mainHours.value += +getShiftHours(shift.plan_work_start_at, shift.plan_work_end_at)
        }
    })
}

async function saveShift() {
    const shift = store.currentShift
    const data: any = shift
    if (shift?.id && store.isShiftEdited) {
        await store.shiftEdit(data)
        await store.fetchSchedule()
    } else if (!shift?.id) {
        data.employment = schedule.value.id
        data.department = schedule.value.department.id
        await store.shiftCreate(data)
    }
    if (store.shiftCopyPack.length) {
        await store.shiftPackCreate(store.shiftCopyPack)
    }
    store.setShiftEditFlag(false)
}

onMounted(() => {
    calcHours()
    if (shifts.value.length) {
        store.setCurrentShift(shifts.value[0])
    }
})

</script>
