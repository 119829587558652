<template>
    <div>
        <div class="flex justify-between border-b border-grayxlight mb-3">
            <div class="text-[13px] semibold text-darkgray">Копирование смены</div>
            <div class="text-xs text-graytextlight cursor-pointer" @click="isOpen = !isOpen">
                <span v-if="isOpen">Свернуть</span>
                <span v-else>Развернуть</span>
            </div>
        </div>
        <div v-if="isOpen">
            <div class="text-gray text-xs mb-3">Выберите дни для копирования</div>
            <VCalendar :attributes="attrs" @dayclick="onDayClick($event)" />
        </div>
    </div>
</template>

<script setup lang="ts">
import dayjs from 'dayjs';
import { ref } from 'vue'
import { useStore } from '../../stores/store'

const store = useStore()
const emit = defineEmits(['on-copy'])

const isOpen = ref(false)

const selectedDates = ref<Date[]>([])

const attrs = ref([
    {
        key: 'today',
        dates: selectedDates.value,
        highlight: true
    }
])

function copy(shift: any, date: Date) {
    const day = +dayjs(date).format('D')
    const tempShift = {
        date: dayjs(date).format('YYYY-MM-DD'),
        employment: shift.employment,
        department: shift.department,
        position: shift.position,
        day_type: shift.day_type,
        is_overtime: shift.is_overtime,
        is_vacancy: shift.is_vacancy,
        plan_work_start_at: dayjs(shift.plan_work_start_at).set('date', day).toISOString(),
        plan_work_end_at: dayjs(shift.plan_work_end_at).set('date', day).toISOString(),
    }
    store.addShiftCopyPack(tempShift)
}

function onDayClick(e: any) {
    console.log(selectedDates.value)
    const index = selectedDates.value.indexOf(e.date)
    if (index > -1) {
        selectedDates.value.splice(index, 1)
        store.removeShiftCopyPack(e.date)
    } else {
        selectedDates.value.push(e.date)
        copy(store.currentShift, e.date)
    }
}
</script>
<style>
.vc-blue {
    font-weight: 400 !important;
    color: #333 !important;
}

.vc-highlight-bg-solid {
    border-radius: 50% !important;
    border: 1px solid #AEDEE2 !important;
    background: none !important;
    color: #666 !important;
}

.vc-highlight-bg-light {
    background: none !important;
}

.vc-highlight-content-light {
    background: #f2f2f2;
}

.vc-title {
    font-weight: 400 !important
}

.vc-focus:focus-within {
    box-shadow: none;
}
</style>