import { ref } from 'vue'
import { defineStore } from 'pinia'
import { getDepartmentTree, getSchedule, getPositions, getDayTypes, editShift, getDepartments, createShift, createShiftPack } from '../api/schedule'
import { Schedule, Position, DayType, Shift, Department } from '../types'
import { useToast } from 'vue-toast-notification'
import dayjs from 'dayjs'
import ru from "dayjs/locale/ru"

dayjs.locale(ru)

const toast = useToast({position: 'top'})

export const useStore = defineStore('Store', () => {
    const schedules = ref<Schedule[]>([])
    const positions = ref<Position[]>([])
    const dayTypes = ref<DayType[]>([])
    const departments = ref<Department[]>([])
    const currentShift = ref<Shift | null>(null)
    const currentScheduleDate = ref(dayjs())
    const currentDepartment = ref<Department>()
    const refresh = ref(0)
    const isShiftEdited = ref(false)
    const shiftCopyPack = ref<Shift[]>([])

    async function fetchDepartmentsTree() {
        try {
            const res = await getDepartmentTree()
            return res.data.body
        } catch(err:any) {
            return err
        }
    }

    async function fetchDepartments() {
        try {
            const res = await getDepartments()
            departments.value = res.data.body
            setCurrentDepartment(departments.value[0])
            await fetchSchedule()
        } catch(err:any) {
            return err
        }
    }

    async function fetchSchedule() {
        const days = currentScheduleDate.value.daysInMonth()
        const start = currentScheduleDate.value.date(1).format('YYYY-MM-DD')
        const end = currentScheduleDate.value.date(days).format('YYYY-MM-DD')
        if(currentDepartment.value) {
            try {
                const res = await getSchedule(currentDepartment.value.id, start, end)
                schedules.value = res.data.body
                refresh.value++
            } catch(err:any) {
                return err
            }
        }
    }

    async function fetchPositions() {
        try {
            const res = await getPositions()
            return positions.value = res.data.body
        } catch(err:any) {
            return err
        }
    }

    async function fetchDayTypes() {
        try {
            const res = await getDayTypes()
            return dayTypes.value = res.data.body
        } catch(err:any) {
            return err
        }
    }

    async function shiftEdit(data: object) {
        try {
            const res = await editShift(data)
            updateScheduleEdit(res.data.body)
            toast.success(res.data.msg)
        } catch(err:any) {
            return err
        }
    }

    async function shiftCreate(data: object) {
        try {
            const res = await createShift(data)
            updateSchedule(res.data.body)
            toast.success(res.data.msg)
        } catch(err:any) {
            return err
        }
    }

    async function shiftPackCreate(data: object) {
        try {
            const res = await createShiftPack(data)
            if(res.data.status === 'ok') toast.success(res.data.msg)
            updateSchedulePack(res.data.body)
        } catch(err:any) {
            return err
        }
    }

    function updateSchedule(shift: any) {
        schedules.value.map(item => {
            if (item.id === shift.employment) {
                item.shifts.push(shift)
                refresh.value++
            }
        })
    }

    function updateScheduleEdit(shift: any) {
        schedules.value.map(item => {
            if (item.id === shift.employment) {
                    item.shifts.map(el => {
                        if(el.id === shift.id) {
                            el = shift
                        }
                    })
                refresh.value++
            }
        })
    }

    function updateSchedulePack(shifts: any[]) {
        schedules.value.map(item => {
            if (item.id === shifts[0].employment) {
                item.shifts.push(...shifts)
                refresh.value++
            }
        })
    }

    function setCurrentShift(shift: Shift) {
        currentShift.value = shift
    }

    function nextScheduleMonth() {
        currentScheduleDate.value = currentScheduleDate.value.add(1, 'month')
        fetchSchedule()
    }

    function prevScheduleMonth() {
        currentScheduleDate.value = currentScheduleDate.value.subtract(1, 'month')
        fetchSchedule()
    }

    function setCurrentDepartment(department: Department) {
        currentDepartment.value = department
    }

    function setShiftEditFlag(value: boolean) {
        isShiftEdited.value = value
    }

    function addShiftCopyPack(shift:Shift) {
        shiftCopyPack.value.push(shift)
    }

    function removeShiftCopyPack(date:Date) {
        shiftCopyPack.value = shiftCopyPack.value.filter(item => item.date !== dayjs(date).format('YYYY-MM-DD'))
    }

    function resetShiftCopyPack() {
        shiftCopyPack.value = []
    }

    return {
        fetchDepartmentsTree, 
        fetchSchedule,
        fetchPositions,
        fetchDayTypes,
        shiftEdit,
        fetchDepartments,
        shiftCreate,
        setCurrentShift,
        shiftPackCreate,
        nextScheduleMonth,
        prevScheduleMonth,
        setCurrentDepartment,
        setShiftEditFlag,
        addShiftCopyPack,
        removeShiftCopyPack,
        resetShiftCopyPack,
        schedules,
        positions,
        dayTypes,
        departments,
        currentShift,
        currentScheduleDate,
        currentDepartment,
        refresh,
        isShiftEdited,
        shiftCopyPack
    }
})