<template>
    <div class="group min-w-12 w-full flex justify-center cursor-pointer border-red relative h-16">
        <div v-if="shiftsNumber > 1" class="text-gray text-sm flex flex-col items-center justify-center">
            <div class="h-4">{{ shiftsNumber }}</div>
            <div class="text-xs h-4">смены</div>
        </div>
        <div v-else-if="shiftsNumber" class="text-gray group">
            <div class="flex flex-col pt-[1px]">
                <div class="text-[11px] h-3">{{ planStart }}</div>
                <div class="text-[11px] h-3 hidden group-hover:block"
                    :class="`text-${shifts[0].violation?.start_status}`" v-if="factStart">
                    {{ factStart }}</div>
                <div class="text-[11px] h-3">{{ planEnd }}</div>
                <div class="text-[11px] h-3 hidden group-hover:block" :class="`text-${shifts[0].violation?.end_status}`"
                    v-if="factEnd">{{ factEnd }}</div>
            </div>
        </div>
        <div v-else class="flex items-center">
            <icon>
                <IconPlus />
            </icon>
        </div>
        <div class="h-[5px] absolute bottom-0 w-full" :class="dayColor">
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { Schedule } from '@/types'
import IconPlus from '@/ui/icons/IconPlus.vue'
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"

interface Props {
    schedule: Schedule,
    day: number
}

dayjs.extend(utc)

const { schedule, day } = defineProps<Props>()

const shifts = schedule.shifts.filter(shift => +dayjs(shift.date).format('D') === day)
const shiftsNumber = shifts.length

const planStart = computed(() => dayjs.utc(shifts[0].plan_work_start_at).format('HH:mm'))
const planEnd = computed(() => dayjs.utc(shifts[0].plan_work_end_at).format('HH:mm'))
const factStart = computed(() => {
    if (shifts[0].fact_work_start_at) {
        return dayjs.utc(shifts[0].fact_work_start_at).format('HH:mm')
    } else if (!shifts[0].fact_work_start_at && shifts[0].violation?.start_status === msg[0]) {
        return '??:??'
    }
})
const factEnd = computed(() => {
    if (shifts[0].fact_work_end_at) { // если отметка сделана
        return dayjs.utc(shifts[0].fact_work_end_at).format('HH:mm')
    } else if (!shifts[0].fact_work_end_at && shifts[0].violation?.end_status === msg[0]) { // нет отметки и статус warning
        return '??:??'
    }
})

const msg = ['warning', 'moderate', 'ok']

const dayColor = computed(() => {
    if (!shifts.length) return ''

    let shift = shifts[0]
    if (shifts.length > 1) {
        shift = shifts.find(shift => shift.violation?.general_status === msg[0])!
        if (!shift) shift = shifts.find(shift => shift.violation?.general_status === msg[1])!
        if (!shift) shift = shifts.find(shift => shift.violation?.general_status === msg[2])!
    }
    return `bg-${shift?.violation?.general_status}`
})

</script>

<style>
.bg-moderate {
    background-color: #FED36B
}

.bg-ok {
    background-color: #a6bd63
}

.bg-warning {
    background-color: #FA7E48
}
</style>